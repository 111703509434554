// External Dependencies
import React, { Component } from 'react';
import isEmail from 'validator/lib/isEmail';

// Internal Dependencies
import {
  PrivacyPolicy,
  TermsOfService,
} from './shared/Links';

// Component Definition
class RaffleEntryForm extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    email: '',
    emailTouched: false,
    entityType: 'Band',
    entityTypeTouched: false,
    joinMailingListChecked: false,
    name: '',
    nameTouched: false,
    privacyPolicyChecked: false,
    school: '',
    schoolTouched: false,
    schoolClassification: '',
    schoolClassificationTouched: false,
  }

  handleBlur = (e) => {
    this.setState({ [`${e.target.name}Touched`]: true });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  render() {
    const {
      email,
      emailTouched,
      entityType,
      entityTypeTouched,
      joinMailingListChecked,
      name,
      nameTouched,
      privacyPolicyChecked,
      school,
      schoolTouched,
      schoolClassification,
      schoolClassificationTouched,
    } = this.state;

    const netlifyFormPath = 'raffle_entry';

    const emailPresent = email.trim().length > 0;
    const emailValid = isEmail(email);
    const entityTypeValid = !!entityType;
    const nameValid = name.trim().length > 0;
    const schoolValid = school.trim().length > 0;
    const schoolClassificationValid = schoolClassification.trim().length > 0;

    let emailErrorMessage = null;
    if (!emailPresent) {
      emailErrorMessage = 'Required';
    } else if (!emailValid) {
      emailErrorMessage = 'Please enter a valid email';
    }

    const entityTypeErrorMessage = !entityTypeValid && 'Required';
    const nameErrorMessage = !nameValid && 'Required';
    const schoolErrorMessage = !schoolValid && 'Required';
    const schoolClassificationErrorMessage = !schoolClassificationValid && 'Required';

    const emailError = emailTouched && !!emailErrorMessage;
    const entityTypeError = entityTypeTouched && !!entityTypeErrorMessage;
    const nameError = nameTouched && !!nameErrorMessage;
    const schoolError = schoolTouched && !!schoolErrorMessage;
    const schoolClassificationError = schoolClassificationTouched
      && !!schoolClassificationErrorMessage;

    const formValid = emailValid && nameValid
      && schoolValid && schoolClassificationValid && privacyPolicyChecked;

    return (
      <div>
        <form
          action={`/${netlifyFormPath}`}
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          method="post"
          name={netlifyFormPath}
        >
          <input type="hidden" name="form-name" value={netlifyFormPath} />

          <p hidden>
            <label htmlFor="hidden">
              Don’t fill this out:{' '}
              <input
                name="bot-field"
                onChange={(e) => this.handleChange(e)}
              />
            </label>
          </p>

          <label htmlFor="name">
            Full Name
            <input
              className={nameError ? 'input-error' : ''}
              id="name"
              name="name"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={name}
            />
            {nameError && (
              <p className="error">{nameErrorMessage}</p>
            )}
          </label>

          <label htmlFor="email">
            Email
            <input
              className={emailError ? 'input-error' : ''}
              id="email"
              name="email"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={email}
            />
            {emailError && (
              <p className="error">{emailErrorMessage}</p>
            )}
          </label>

          <label htmlFor="email">
            Organization Type
            <select
              className={emailError ? 'input-error' : ''}
              id="entityType"
              name="entityType"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              value={entityType}
            >
              <option>Band</option>
              <option>Choir</option>
              <option>Dance</option>
              <option>Orchestra</option>
              <option>Theater</option>
            </select>
            {entityTypeError && (
              <p className="error">{entityTypeErrorMessage}</p>
            )}
          </label>

          <label htmlFor="school">
            School
            <input
              className={schoolError ? 'input-error' : ''}
              id="school"
              name="school"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={school}
            />
            {schoolError && (
              <p className="error">{schoolErrorMessage}</p>
            )}
          </label>

          <label htmlFor="school">
            School Classification
            <input
              className={schoolError ? 'input-error' : ''}
              id="schoolClassification"
              name="schoolClassification"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={schoolClassification}
            />
            {schoolClassificationError && (
              <p className="error">{schoolClassificationErrorMessage}</p>
            )}
          </label>

          <p hidden>
            <label htmlFor="hidden">
              Convention
              <input
                name="convention"
                value={process.env.GATSBY_APP_CONVENTION_NAME}
              />
            </label>
          </p>

          <section>
            <input
              checked={privacyPolicyChecked}
              id="privacy-policy"
              name="privacyPolicyChecked"
              onChange={this.handleCheck}
              type="checkbox"
              value={privacyPolicyChecked}
            />
            <label htmlFor="privacy-policy">
              I have read and acknowledge Presto&apos;s
              Terms of Service (<TermsOfService linkText="link" />){' '}
              and Privacy Policy (<PrivacyPolicy linkText="link" />).
            </label>

            <input
              checked={joinMailingListChecked}
              id="join-mailing-list"
              name="joinMailingListChecked"
              onChange={this.handleCheck}
              type="checkbox"
              value={joinMailingListChecked}
            />
            <label htmlFor="join-mailing-list">
              Keep me updated with the latest news from Presto Assistant.
            </label>
          </section>

          <div className="button-wrapper">
            <button
              className="button special-2"
              disabled={!formValid}
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default RaffleEntryForm;
