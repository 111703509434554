// External Dependencies
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import RaffleEntryForm from '../components/RaffleEntryForm';

// Component definition
const RaffleEntry = () => (
  <Layout>
    <Meta title="Raffle Entry" />
    <HeaderGeneric
      title={`${process.env.GATSBY_APP_CONVENTION_NAME} Raffle`}
      subtitle="Enter to win a free year of Presto Assistant!"
    />

    <div id="main" className="form">
      <section id="content" className="main">
        <RaffleEntryForm />

        <h5 className="align-center">
          Raffle contestants must be new customers to be eligible to win.
        </h5>
      </section>
    </div>

  </Layout>
);

export default RaffleEntry;
